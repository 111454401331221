// src/main.js
import { createApp } from "vue";
import { createI18n } from "vue-i18n";

import App from "./App.vue";
import router from "./router";

// 번역 파일 불러오기
import en from "./locales/en.json";
import ko from "./locales/ko.json";

// Vue I18n 인스턴스 생성
const i18n = createI18n({
  locale: "en", // 기본 언어 설정
  fallbackLocale: "en", // 선택한 언어가 없을 때 기본 언어
  messages: {
    en,
    ko,
  },
});

const app = createApp(App);
app.use(router); // 애플리케이션에 라우터 연결
app.use(i18n); // 애플리케이션에 I18n 연결
app.mount("#app");
