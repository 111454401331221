<template>
  <div class="custom-dropdown">
    <div class="selected-option" @click="toggleDropdown">
      <img v-if="selectedFlag" :src="selectedFlag" class="flag-icon" />
      <span>{{ selectedName || $t("selectCountry") }}</span>
      <span class="arrow">{{ isOpen ? "▲" : "▼" }}</span>
    </div>
    <div v-if="isOpen" class="options">
      <div v-for="(country, code) in countriesWithFlags" :key="code" class="option" @click="selectCountry(code, country.name)">
        <img v-if="country.flag" :src="country.flag" class="flag-icon" />
        <span>{{ country.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import countries from "@/utils/countries.js";

export default {
  props: {
    modelValue: String, // v-model과 연결될 값
  },
  data() {
    return {
      isOpen: false,
      selectedName: "",
      countriesWithFlags: {},
    };
  },
  computed: {
    selectedFlag() {
      return this.modelValue ? this.getFlagImagePath(this.modelValue) : null;
    },
  },
  mounted() {
    this.loadFlags();
    this.setSelectedName();
  },
  watch: {
    modelValue() {
      this.setSelectedName();
    },
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectCountry(code, name) {
      this.$emit("update:modelValue", code); // v-model에 값 전달
      this.selectedName = name;
      this.isOpen = false;
    },
    setSelectedName() {
      if (this.modelValue && this.countriesWithFlags[this.modelValue]) {
        this.selectedName = this.countriesWithFlags[this.modelValue].name;
      }
    },
    async loadFlags() {
      const loadedFlags = {};

      for (const [code, name] of Object.entries(countries)) {
        try {
          const flagPath = await this.loadImage(this.getFlagImagePath(code));
          loadedFlags[code] = { name, flag: flagPath };
        } catch {
          loadedFlags[code] = { name, flag: null };
          console.warn(`이미지 없음: ${code}`);
        }
      }

      this.countriesWithFlags = loadedFlags;
    },
    getFlagImagePath(code) {
      return `/flags/${code}.gif`; // 이 경로가 올바른지 확인
    },
    loadImage(src) {
      return new Promise((resolve, reject) => {
        const img = new Image();

        img.onload = () => resolve(src);
        img.onerror = () => reject(false);

        img.src = src;
      });
    },
  },
};
</script>

<style scoped>
.custom-dropdown {
  position: relative;
  width: 100%;
}

.selected-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border: 1px solid #ccc;
  cursor: pointer;
  margin-top:5px;
}

.options {
  position: absolute;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  background-color: white;
  z-index: 1000;
}

.option {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

.option:hover {
  background-color: #f0f0f0;
}

.flag-icon {
  width: 20px;
  height: 15px;
  margin-right: 8px;
}

.arrow {
  font-size: 0.8em;
}
</style>
