<template>
  <div class="planner-page">
    <div class="header">
      <h3 class="title">[견적 작성]</h3>
      <div class="exchange-rate-converter">
        <div class="conversion-info">
          <small>환율 기준 : 한국시간 {{ updateTime }}</small>
        </div>
        <div class="input-group">
          <div class="left-group">
            <input v-model.number="amount" type="number" min="0" class="amount-input" @input="convertCurrency('fromLeft')" />
            <select v-model="baseCurrency" class="currency-select">
              <option v-for="currency in filteredCurrencies" :key="currency.code" :value="currency.code">
                {{ currency.code }}
              </option>
            </select>
          </div>
          <span class="equals-sign">=</span>
          <div class="right-group">
            <div class="currency-row">
              <input v-model.number="convertedAmount" type="number" min="0" class="amount-input" @input="convertCurrency('fromRight')" />
              <select v-model="targetCurrency" class="currency-select">
                <option v-for="currency in filteredCurrencies" :key="currency.code" :value="currency.code">
                  {{ currency.code }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="body">
      <div class="sidebar">
        <button @click="showCategory('schedule')" :class="{ active: selectedCategory === 'schedule' }">{{ $t("schedule") }}</button>
        <button @click="showCategory('accommodation')" :class="{ active: selectedCategory === 'accommodation' }">{{ $t("accommodation") }}</button>
        <button @click="showCategory('food')" :class="{ active: selectedCategory === 'food' }">{{ $t("food") }}</button>
      </div>

      <div class="content" :style="{ backgroundColor: contentBackgroundColor }">
        <V_1Schedule v-if="selectedCategory === 'schedule'" :selectedDate="selectedDate" @date-selected="handleDateSelected" />
        <V_2Accommodation v-if="selectedCategory === 'accommodation'" :accommodations="accommodations" />
        <V_3Food v-if="selectedCategory === 'food'" :foods="foods" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import V_1Schedule from "./V_1Schedule.vue";
import V_2Accommodation from "./V_2Accommodation.vue";
import V_3Food from "./V_3Food.vue";
import { currencies } from "@/utils/currencies.js";
import apiService from "@/api/apiService";

export default {
  name: "V_0Main",
  components: {
    V_1Schedule,
    V_2Accommodation,
    V_3Food,
  },
  setup() {
    const selectedCategory = ref(null);
    const foods = ref(["김치찌개", "청국장", "해장국"]);
    const selectedDate = ref(null);
    const amount = ref(1);
    const baseCurrency = ref("USD");
    const targetCurrency = ref("KRW");
    const convertedAmount = ref(0);
    const updateTime = ref("");
    const exchangeRates = ref({});
    const currenciesList = ref(currencies);

    const contentBackgroundColor = computed(() => (selectedCategory.value ? "#f8f8f8" : "#f8f8f8"));
    const filteredCurrencies = computed(() => currenciesList.value.filter((currency) => exchangeRates.value && exchangeRates.value[currency.code]));

    const showCategory = (category) => {
      selectedCategory.value = category;
    };

    const handleDateSelected = (date) => {
      selectedDate.value = date;
    };

    const getExchangeRates = async () => {
      try {
        const { exchangeRates: rates, updateTime: update } = await apiService.getExchangeRates();
        const utcDate = new Date(update);
        updateTime.value = utcDate.toLocaleString("ko-KR", { timeZone: "Asia/Seoul" });
        exchangeRates.value = rates;
        convertCurrency("fromLeft");
      } catch (error) {
        console.error("Error fetching exchange rates:", error.message);
      }
    };

    const convertCurrency = (direction) => {
      const baseRate = exchangeRates.value[baseCurrency.value];
      const targetRate = exchangeRates.value[targetCurrency.value];

      if (baseRate && targetRate) {
        if (direction === "fromLeft") {
          convertedAmount.value = (amount.value * (targetRate / baseRate)).toFixed(6);
        } else if (direction === "fromRight") {
          amount.value = (convertedAmount.value * (baseRate / targetRate)).toFixed(6);
        }
      } else {
        convertedAmount.value = "N/A";
      }
    };

    onMounted(() => {
      getExchangeRates();
    });

    return {
      selectedCategory,
      foods,
      selectedDate,
      amount,
      baseCurrency,
      targetCurrency,
      convertedAmount,
      updateTime,
      exchangeRates,
      currencies: currenciesList,
      contentBackgroundColor,
      filteredCurrencies,
      showCategory,
      handleDateSelected,
      getExchangeRates,
      convertCurrency,
    };
  },
};
</script>

<style scoped>
/* 기존 스타일 유지 */
.planner-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

.header {
  width: 100%;
  background-color: #ffffff;
  padding: 20px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.exchange-rate-converter {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 80px;
}

.conversion-info {
  font-size: 20px;
  color: #666;
  margin-bottom: 20px;
}

.input-group {
  display: flex;
  align-items: center;
  gap: 8px;
}

.left-group,
.right-group .currency-row {
  display: flex;
  align-items: center;
  gap: 4px;
}

.amount-input::-webkit-outer-spin-button,
.amount-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.amount-input {
  width: 120px;
  padding: 8px;
  font-size: 16px;
  text-align: right;
  -moz-appearance: textfield;
}

.currency-select {
  padding: 8px;
  font-size: 16px;
}

.equals-sign {
  font-size: 16px;
  font-weight: bold;
  margin: 0 4px;
}

.body {
  display: flex;
  flex: 1;
  width: 100%;
}

.sidebar {
  width: 220px;
  background-color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-right: 1px solid #ddd;
}

.sidebar button {
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  background-color: #ffffff;
  color: #333;
  border: 1px solid #ddd;
  cursor: pointer;
  border-radius: 4px;
  text-align: left;
  font-size: 16px;
}

.sidebar button.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.content {
  flex: 1;
  padding: 40px;
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
}
</style>
