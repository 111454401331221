<template>
  <div class="quote-request-page">
    <!-- 상단 바 -->
    <div class="top-bar">
      <div class="left-section">
        <button class="button-new" @click="goToRequest">{{ $t("new") }}</button>
        <!--  <span class="available-count">{{ $t("available_count") }}: {{ available }}</span>  -->
      </div>
      <div class="user-info">
        <span v-if="isLoggedIn" class="link" @click="goToUserProfile">{{ nickname }}</span>
      </div>
    </div>

    <div class="title">
      <h1>Quote History</h1>
    </div>

    <table class="history-table">
      <thead>
        <tr>
          <th>No.</th>
          <th>{{ $t("date") }}</th>
          <th>{{ $t("status") }}</th>
          <th>{{ $t("productName") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(request, index) in requests" :key="request.id_request" @click="navigateByStatus(request)" class="clickable-row">
          <td>{{ index + 1 }}</td>
          <td>{{ formatDate(request.date_request) }}</td>
          <td>{{ getStatusText(request.status) }}</td>
          <td>{{ request.product }}</td>
        </tr>
        <tr v-if="requests.length === 0">
          <td colspan="6" class="no-data">{{ $t("noRequests") }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import apiService from "@/api/apiService";
import { doLogout } from "@/components/authentication/AuthService";

export default {
  name: "P_0Main",
  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const requests = ref([]);
    const isLoggedIn = ref(localStorage.getItem("LoggedIn") === "true");
    const nickname = ref(localStorage.getItem("Nickname"));
    const available = ref(localStorage.getItem("Available") || "0");

    if (!isLoggedIn.value) {
      router.push("/login");
    }

    const goToUserProfile = () => {
      router.push("/user-profile");
    };

    const goToRequest = () => {
      router.push({ name: "P_1Request" }); // 페이지 이동
    };

    const checkUserType = () => {
      const userType = localStorage.getItem("UserType");
      console.log("@@ userType : ", userType);
      if (userType !== "customer") {
        alert("권한이 없습니다. 메인 페이지로 이동합니다.");
        doLogout();
        router.push("/");
        return false;
      }
      return true;
    };

    const getRequestList = async () => {
      if (!checkUserType()) return; // 권한 확인 후 요청 수행

      try {
        const userId = localStorage.getItem("UserId");
        if (!userId) {
          throw new Error("User ID not found.");
        }

        const userType = localStorage.getItem("UserType");
        if (!userType) {
          throw new Error("User Type not found.");
        }

        const data = await apiService.getRequestList({ userId, userType });
        requests.value = data;
      } catch (error) {
        console.error("Failed to fetch quote requests:", error);
      }
    };

    const formatDate = (date) => {
      const options = { month: "short", day: "numeric" }; // 월, 일
      return new Date(date).toLocaleDateString("en-US", options);
    };

    const getStatusText = (status) => {
      const statusMap = {
        1: t("status_requested"),
        2: t("status_issued"),
        3: t("status_paid"),
        4: t("status_canceled"),
        5: t("status_expired"),
      };
      return statusMap[status] || t("unknown");
    };

    const navigateByStatus = (request) => {
      console.log("navigateByStatus request:", request);

      if (request.status === 1) {
        router.push({
          path: `/product/edit-request/${request.id_request}`,
          query: {
            product: request.product,
            quantity: request.quantity,
            brand: request.brand,
            referenceLink: request.link || "",
            hasImage: request.has_image ? 1 : 0,
            extraRequest: request.extra_request || "",
          },
        });
      } else if (request.status === 2) {
        router.push({
          name: "P_2Payment",
          params: { id_request: request.id_request },
        });
      } else {
        alert(t("unsupported_status"));
      }
    };

    onMounted(() => {
      getRequestList();
    });

    return { isLoggedIn, requests, nickname, available, goToRequest, goToUserProfile, formatDate, getStatusText, navigateByStatus };
  },
};
</script>

<style scoped>
.quote-request-page {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0;
  margin-top: 0;
  min-height: 0;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.left-section {
  display: flex;
  align-items: center; /* New 버튼과 Available Count를 세로로 정렬 */
}

.button-new {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px; /* Available Count와 간격 추가 */
}

.button-new:hover {
  background-color: #0056b3;
}

.available-count {
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap; /* 텍스트 줄바꿈 방지 */
}

.user-info {
  position: fixed;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
}

.user-info .link {
  cursor: pointer;
  color: #007bff;
  font-weight: bold;
  font-size: 22px;
}

.history-table {
  width: 100%;
  border-collapse: collapse;
}

.history-table th,
.history-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.history-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.history-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.no-data {
  text-align: center;
  color: #888;
  font-style: italic;
}

.clickable-row {
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.clickable-row:hover {
  background-color: #c1c1c1 !important;
}
</style>
