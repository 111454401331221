<template>
  <div class="calendar-container">
    <div class="calendar">
      <div class="calendar-info">총 {{ currentMonthDaysCount }}일</div>
      <div class="calendar-header">
        <button @click="prevMonth">◀</button>
        <span>{{ currentYear }}년 {{ currentMonth + 1 }}월</span>
        <button @click="nextMonth">▶</button>
      </div>
      <div class="calendar-body">
        <div class="week-names">
          <div v-for="(day, index) in weekNames" :key="index" class="week-name">
            {{ day }}
          </div>
        </div>
        <div class="dates">
          <div v-for="(date, index) in calendarDates" :key="index" :class="['date', getDateClass(date)]" @click="selectDate(date)">
            {{ date.date }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";

export default {
  name: "V_1Schedule",
  setup(_, { emit }) {
    const currentYear = ref(new Date().getFullYear());
    const currentMonth = ref(new Date().getMonth());
    const selectedStartDate = ref(null);
    const selectedEndDate = ref(null);
    const weekNames = ref(["일", "월", "화", "수", "목", "금", "토"]);

    const calendarDates = computed(() => {
      const dates = [];
      const firstDayOfMonth = new Date(currentYear.value, currentMonth.value, 1).getDay();
      const lastDateOfMonth = new Date(currentYear.value, currentMonth.value + 1, 0).getDate();
      const prevMonthLastDate = new Date(currentYear.value, currentMonth.value, 0).getDate();

      for (let i = firstDayOfMonth - 1; i >= 0; i--) {
        dates.push({
          date: prevMonthLastDate - i,
          isCurrentMonth: false,
          dateObj: new Date(currentYear.value, currentMonth.value - 1, prevMonthLastDate - i),
        });
      }

      for (let i = 1; i <= lastDateOfMonth; i++) {
        const isToday = currentYear.value === new Date().getFullYear() && currentMonth.value === new Date().getMonth() && i === new Date().getDate();
        const dateObj = new Date(currentYear.value, currentMonth.value, i);
        dates.push({
          date: i,
          isCurrentMonth: true,
          isToday,
          dateObj,
        });
      }

      const nextMonthDays = 42 - dates.length;
      for (let i = 1; i <= nextMonthDays; i++) {
        dates.push({
          date: i,
          isCurrentMonth: false,
          dateObj: new Date(currentYear.value, currentMonth.value + 1, i),
        });
      }

      return dates;
    });

    const currentMonthDaysCount = computed(() => {
      if (selectedStartDate.value && selectedEndDate.value) {
        const timeDiff = selectedEndDate.value.getTime() - selectedStartDate.value.getTime();
        return Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) + 1;
      } else if (selectedStartDate.value) {
        return 1;
      } else {
        return 0;
      }
    });

    const prevMonth = () => {
      if (currentMonth.value === 0) {
        currentYear.value--;
        currentMonth.value = 11;
      } else {
        currentMonth.value--;
      }
    };

    const nextMonth = () => {
      if (currentMonth.value === 11) {
        currentYear.value++;
        currentMonth.value = 0;
      } else {
        currentMonth.value++;
      }
    };

    const selectDate = (date) => {
      if (!date.isCurrentMonth) return;

      if (!selectedStartDate.value || (selectedStartDate.value && selectedEndDate.value)) {
        selectedStartDate.value = date.dateObj;
        selectedEndDate.value = null;
      } else if (!selectedEndDate.value && date.dateObj >= selectedStartDate.value) {
        selectedEndDate.value = date.dateObj;
      } else {
        selectedStartDate.value = date.dateObj;
        selectedEndDate.value = null;
      }

      emit("date-selected", {
        startDate: selectedStartDate.value,
        endDate: selectedEndDate.value,
      });
    };

    const getDateClass = (date) => {
      if (!date.isCurrentMonth) return "not-current-month";
      if (selectedStartDate.value && date.dateObj.getTime() === selectedStartDate.value.getTime()) {
        return "selected-start";
      }
      if (selectedEndDate.value && date.dateObj.getTime() === selectedEndDate.value.getTime()) {
        return "selected-end";
      }
      if (selectedStartDate.value && selectedEndDate.value && date.dateObj > selectedStartDate.value && date.dateObj < selectedEndDate.value) {
        return "in-range";
      }
      return "";
    };

    return {
      currentYear,
      currentMonth,
      selectedStartDate,
      selectedEndDate,
      weekNames,
      calendarDates,
      currentMonthDaysCount,
      prevMonth,
      nextMonth,
      selectDate,
      getDateClass,
    };
  },
};
</script>

<style scoped>
.calendar {
  width: 100%;
  max-width: 600px;
  font-family: Arial, sans-serif;
  margin: 0 auto;
  background-color: #ffffff;
}

.calendar-info {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 18px;
  background-color: transparent;
}

.calendar-header button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #007bff;
}

.calendar-body {
  padding: 10px;
}

.week-names,
.dates {
  display: flex;
  flex-wrap: wrap;
}

.week-name,
.date {
  width: calc(100% / 7);
  text-align: center;
  padding: 10px 0;
  box-sizing: border-box;
}

.date {
  margin: 5px 0;
  cursor: pointer;
}

.not-current-month {
  color: #ccc;
  opacity: 0.5;
}

.today {
  background-color: #f0f0f0;
  font-weight: bold;
  border-radius: 4px;
}

.selected-start,
.selected-end,
.in-range {
  background-color: #cce4ff;
  color: black;
  border-radius: 4px;
}
</style>
