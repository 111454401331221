<template>
  <div v-if="isLoggedIn" class="request-page">
    <!-- 좌측 상단에 견적 신청 가능 횟수 표시 -->
    <div class="available-count">{{ $t("available_count") }}: {{ available }}</div>

    <h1>{{ $t("quoteRequest") }}</h1>

    <div class="form-group">
      <label for="product">{{ $t("productName") }} :</label>
      <input v-model="dataRequest.product" type="text" id="product" class="text-input" :placeholder="$t('productNameHint')" />
    </div>

    <div class="form-group">
      <label for="quantity">{{ $t("quantity") }} :</label>
      <input v-model.number="dataRequest.quantity" type="number" id="quantity" class="number-input" min="1" />
    </div>

    <div class="form-group">
      <label for="brand">{{ $t("brand") }} :</label>
      <input v-model="dataRequest.brand" type="text" id="brand" class="text-input" :placeholder="$t('brandHint')" />
    </div>

    <div class="form-group">
      <label for="referenceLink">{{ $t("referenceLink") }} :</label>
      <input v-model="dataRequest.referenceLink" type="url" id="referenceLink" class="text-input" :placeholder="$t('referenceLinkHint')" />
    </div>

    <!-- Customer Image와 + 버튼 -->
    <div class="form-group">
      <label>{{ $t("referenceImage") }} :</label>

      <div v-for="(image, index) in customerImages" :key="index" class="image-input-group">
        <!-- 전체 영역을 div로 감싸기 -->
        <div class="file-box">
          <div class="image-preview" @click="showImageModal(image.url)">
            <img v-if="image.url" :src="image.url" alt="Uploaded Image" />
            <span v-else>No image</span>
            <!-- 모달 -->
            <div v-if="isModalVisible" class="modal-overlay" @click="closeModal">
              <div class="modal-content" @click.stop>
                <img :src="modalImage" alt="Full Image" class="modal-img-size" />
                <!-- X 버튼 추가 -->
                <button class="modal-close-button" @click="closeModal">X</button>
              </div>
            </div>
          </div>
          <div class="image-button-group">
            <!-- - 버튼: 이미지를 선택한 경우에만 표시 -->
            <button v-if="image.url" type="button" class="image-button-remove" @click="removeImageSlot(index)">-</button>
            <!-- Select File 버튼 -->
            <button type="button" class="image-button-change" @click="changeImageFile(index)">Select File</button>
            <input ref="'customerImage' + index" type="file" :id="'customerImage' + index" class="file-input" @change="loadImageFile($event, index)" accept="image/*" />
            <!-- + 버튼: 마지막 슬롯이며 최대 5개가 아닐 때만 표시 -->
            <button v-if="image.url && index === customerImages.length - 1 && customerImages.length < 5" type="button" class="image-button-add" @click="addImageSlot">+</button>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="extraRequest">{{ $t("additionalRequests") }} :</label>
      <textarea v-model="dataRequest.extraRequest" id="extraRequest" class="text-area-input" :placeholder="$t('additionalRequestsHint')" maxlength="1000" @input="updateCharCount"></textarea>
      <div class="char-counter">{{ charCount }}/1000</div>
    </div>

    <div>
      <button @click.prevent="setProductRequest" :disabled="!isFormValid">{{ $t("requestButton") }}</button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import apiService from "@/api/apiService";

export default {
  name: "P_1Request",
  setup() {
    const router = useRouter();

    const isLoggedIn = ref(localStorage.getItem("LoggedIn") === "true");
    const available = computed(() => localStorage.getItem("Available") || "0"); // 기본값 0
    const isModalVisible = ref(false);
    const modalImage = ref("");
    const charCount = ref(0);

    const customerImages = ref([]);

    // for product_requests table
    const dataRequest = ref({
      product: "",
      quantity: 1,
      brand: "",
      referenceLink: "",
      extraRequest: "",
    });

    if (!isLoggedIn.value) {
      router.push("/login");
    }

    const loadImageFile = (event, index) => {
      console.log(`File upload triggered for index: ${index}`); // 디버깅
      console.log("Current customerImages before update:", customerImages.value); // 배열 상태 확인
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          customerImages.value[index] = {
            file,
            fileName: file.name,
            url: reader.result, // 파일의 Data URL을 미리보기로 사용
            fileIndex: index + 1,
          };
        };
        reader.readAsDataURL(file);
      }
    };

    const showImageModal = (url) => {
      if (url) {
        modalImage.value = url;
        isModalVisible.value = true;
      }
    };

    const closeModal = () => {
      isModalVisible.value = false;
      modalImage.value = "";
    };

    const changeImageFile = (index) => {
      const fileInput = document.getElementById(`customerImage${index}`);
      if (fileInput) {
        fileInput.click(); // 파일 선택 창 열기
      }
    };

    const removeImageSlot = (index) => {
      if (index < 0 || index >= customerImages.value.length) {
        console.warn("Invalid slot index.");
        return;
      }

      console.log(`Removing slot ${index + 1}. Current customerImages:`, customerImages.value);

      if (customerImages.value.length === 1) {
        // 마지막 슬롯만 남아있을 때는 데이터를 초기화
        customerImages.value[0] = {
          file: null,
          fileName: null,
          url: null,
          fileIndex: 1, // 기본 슬롯 번호
        };
      } else {
        // 특정 슬롯 제거
        customerImages.value = customerImages.value.filter((_, i) => i !== index);

        // 마지막 슬롯에 대한 추가 처리: "No image" 상태 보장
        const lastIndex = customerImages.value.length - 1;
        if (lastIndex >= 0 && customerImages.value[lastIndex].url === null && customerImages.value[lastIndex].file === null) {
          customerImages.value[lastIndex] = {
            ...customerImages.value[lastIndex],
            file: null,
            fileName: null,
            url: null,
          };
        }
      }

      console.log(`Updated customerImages after removal:`, customerImages.value);
    };

    const addImageSlot = () => {
      console.log("Adding new slot. Current customerImages:", customerImages.value); // 디버깅
      if (customerImages.value.length < 5) {
        customerImages.value.push({
          file: null,
          fileName: null,
          url: null,
          fileIndex: customerImages.value.length + 1,
        });
        console.log("Slot added. Updated customerImages:", customerImages.value); // 배열 상태 확인
      } else {
        alert("최대 5개의 이미지만 업로드할 수 있습니다.");
      }
    };

    const updateCharCount = () => {
      charCount.value = dataRequest.value.extraRequest.length;
    };

    const isSubmitting = ref(false); // 요청 중 상태 관리

    const setProductRequest = async () => {
      if (isSubmitting.value) return; // 이미 요청 중이면 실행하지 않음
      isSubmitting.value = true;
      console.log("Current customerImages:", customerImages.value);

      try {
        const formData = new FormData();
        formData.append("userId", localStorage.getItem("UserId"));
        formData.append("idRequest", null);
        formData.append("product", dataRequest.value.product);
        formData.append("quantity", dataRequest.value.quantity);
        formData.append("brand", dataRequest.value.brand);
        formData.append("referenceLink", dataRequest.value.referenceLink);
        formData.append("extraRequest", dataRequest.value.extraRequest);

        // 최종적으로 슬롯 번호 조정
        customerImages.value.forEach((image, index) => {
          if (image && image.file) {
            formData.append(`customerImage${index + 1}`, image.file);
          }
        });

        const response = await apiService.setProductRequest(formData);
        console.log("Request response:", response);
        alert("요청이 제출되었습니다.");
        router.push("/product");
      } catch (error) {
        console.error("Error submitting request:", error.message);
        alert("요청 제출 중 오류가 발생했습니다.");
      } finally {
        isSubmitting.value = false; // 요청 완료 후 상태 초기화
      }
    };

    const isFormValid = computed(() => {
      const { product, quantity, brand } = dataRequest.value;
      return product.trim() !== "" && quantity > 0 && brand.trim() !== "";
    });

    onMounted(() => {
      if (customerImages.value.length === 0) {
        customerImages.value.push({
          file: null,
          fileName: null,
          url: null,
          fileIndex: 1, // 기본 슬롯 번호
        });
      }
    });

    return {
      isLoggedIn,
      available,
      dataRequest,
      customerImages,
      charCount,
      isFormValid,
      isModalVisible,
      modalImage,
      updateCharCount,
      loadImageFile,
      changeImageFile,
      showImageModal,
      closeModal,
      removeImageSlot,
      addImageSlot,
      setProductRequest,
    };
  },
};
</script>

<style scoped>
.request-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0;
  margin-top: 0;
  min-height: 0;
}

.available-count {
  position: fixed;
  top: 10px;
  left: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  width: 100%;
  max-width: 80%;
}

label {
  margin-bottom: 1px;
  font-weight: bold;
}

.image-input-group {
  margin-bottom: 8px;
}

.image-button-group {
  display: flex;
  flex-direction: column;
  align-items: center; /* 버튼들을 가운데 정렬 */
  gap: 10px; /* 버튼들 간 간격 */
}

.image-button-change,
.image-button-remove,
.image-button-add {
  margin-left: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 4px 8px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
}

.image-button-change:hover,
.image-button-remove:hover,
.image-button-add:hover {
  background-color: #0056b3;
}

/* 클릭 가능한 전체 박스 */
.file-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%; /* 부모 컨테이너의 너비를 채움 */
  max-width: 100%; /* 다른 input과 일치 */
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  gap: 16px;
  box-sizing: border-box; /* padding 포함한 크기 계산 */
}

.file-box:hover {
  border-color: #aaa; /* hover 시 테두리 색상 */
}

/* 숨겨진 파일 입력 */
.file-input {
  display: none;
}

/* 이미지 미리보기 */
.image-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border: none;
  border-radius: 4px;
  overflow: hidden;
}

.image-preview img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.modal-img-size {
  max-width: 70vw !important;
  max-height: 70vh !important;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
}

.modal-content {
  max-width: 90%;
  max-height: 90%;
  position: relative;
}

.modal-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
  cursor: default;
}

.modal-close-button {
  z-index: 1002; /* X 버튼은 모달 내용보다 위 */
  background-color: #007bff; /* 테스트용 배경색 */
  color: white; /* 글자 색상 */
  position: absolute; /* 위치 고정 */
  top: 10px; /* 모달 상단에서 10px 아래 */
  right: 10px; /* 모달 오른쪽에서 10px 왼쪽 */
  border: none;
  font-size: 20px; /* 버튼 크기 */
  font-weight: bold;
  cursor: pointer; /* 클릭 가능 */
  z-index: 1002; /* 다른 요소보다 앞에 표시 */
  width: 40px; /* 테스트용 버튼 너비 */
  height: 40px; /* 테스트용 버튼 높이 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-close-button:hover {
  color: #ccc; /* 호버 시 색상 변경 */
}

/* Change File 버튼 */
.file-change-button {
  padding: 8px 16px;
  font-size: 14px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.file-change-button:hover {
  background-color: #0056b3;
}

.custom-file-label {
  display: inline-block;
  padding: 4px 8px;
  font-size: 14px;
  color: #fff;
  background-color: #007bff;
  border-radius: 4px;
  cursor: pointer;
}

.custom-file-label:hover {
  background-color: #0056b3;
}

.number-input,
.text-input,
.text-area-input,
.test-box {
  display: block;
  width: 100%; /* 부모 크기를 채우도록 */
  max-width: 100%; /* 부모 제한에 맞춤 */
  padding: 8px; /* 동일한 패딩 */
  font-size: 16px;
  border: 1px solid #ccc; /* 동일한 테두리 */
  border-radius: 4px;
  box-sizing: border-box; /* 패딩과 경계를 포함한 크기 계산 */
  background-color: #fff; /* 동일한 배경색 */
}

.text-area-input {
  height: 200px;
  resize: vertical;
}

.char-counter {
  font-size: 12px;
  color: #666;
  text-align: right;
}

button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  padding: 10px 20px;
}

button:hover {
  opacity: 0.9;
}

button:hover:enabled {
  background-color: #0056b3;
}

button:disabled {
  padding: 10px 20px;
  background-color: #ccc;
  cursor: not-allowed;
}
</style>
