// src/utils/currencies.js
export const currencies = [
  { code: "USD", country: "United States" },
  { code: "EUR", country: "Eurozone" },
  { code: "JPY", country: "Japan" },
  { code: "GBP", country: "United Kingdom" },
  { code: "AUD", country: "Australia" },
  { code: "CAD", country: "Canada" },
  { code: "CHF", country: "Switzerland" },
  { code: "CNY", country: "China" },
  { code: "HKD", country: "Hong Kong" },
  { code: "KRW", country: "South Korea" },
  { code: "INR", country: "India" },
  { code: "RUB", country: "Russia" },
  { code: "BRL", country: "Brazil" },
  { code: "ZAR", country: "South Africa" },
  { code: "MXN", country: "Mexico" },
  { code: "SGD", country: "Singapore" },
  { code: "NZD", country: "New Zealand" },
  { code: "SEK", country: "Sweden" },
  { code: "NOK", country: "Norway" },
  { code: "DKK", country: "Denmark" },
  { code: "PLN", country: "Poland" },
  { code: "HUF", country: "Hungary" },
  { code: "CZK", country: "Czech Republic" },
  { code: "ILS", country: "Israel" },
  { code: "MYR", country: "Malaysia" },
  { code: "PHP", country: "Philippines" },
  { code: "THB", country: "Thailand" },
  { code: "IDR", country: "Indonesia" },
  { code: "VND", country: "Vietnam" },
  { code: "SAR", country: "Saudi Arabia" },
  { code: "AED", country: "United Arab Emirates" },
  { code: "TRY", country: "Turkey" },
  { code: "EGP", country: "Egypt" },
  { code: "NGN", country: "Nigeria" },
  { code: "KES", country: "Kenya" },
  { code: "GHS", country: "Ghana" },
  { code: "LKR", country: "Sri Lanka" },
  { code: "PKR", country: "Pakistan" },
  { code: "BDT", country: "Bangladesh" },
  { code: "NPR", country: "Nepal" },
  { code: "BHD", country: "Bahrain" },
  { code: "KWD", country: "Kuwait" },
  { code: "OMR", country: "Oman" },
  { code: "QAR", country: "Qatar" },
  { code: "JOD", country: "Jordan" },
  { code: "MAD", country: "Morocco" },
  { code: "DZD", country: "Algeria" },
  { code: "TND", country: "Tunisia" },
  { code: "LBP", country: "Lebanon" },
  { code: "SYP", country: "Syria" },
  { code: "IQD", country: "Iraq" },
  { code: "IRR", country: "Iran" },
  { code: "YER", country: "Yemen" },
  { code: "AFN", country: "Afghanistan" },
  { code: "UZS", country: "Uzbekistan" },
  { code: "KZT", country: "Kazakhstan" },
  { code: "TJS", country: "Tajikistan" },
  { code: "TMT", country: "Turkmenistan" },
  { code: "AZN", country: "Azerbaijan" },
  { code: "AMD", country: "Armenia" },
  { code: "GEL", country: "Georgia" },
  { code: "BYN", country: "Belarus" },
  { code: "UAH", country: "Ukraine" },
  { code: "MDL", country: "Moldova" },
  { code: "RON", country: "Romania" },
  { code: "BGN", country: "Bulgaria" },
  { code: "HRK", country: "Croatia" },
  { code: "RSD", country: "Serbia" },
  { code: "MKD", country: "North Macedonia" },
  { code: "ALL", country: "Albania" },
  { code: "ISK", country: "Iceland" },
  { code: "BAM", country: "Bosnia and Herzegovina" },
  { code: "MZN", country: "Mozambique" },
  { code: "ZMW", country: "Zambia" },
  { code: "MWK", country: "Malawi" },
];
