<template>
  <div class="user-profile">
    <h2 class="userInfoTitle">User Information</h2>
    <div class="profile-info">
      <div class="profile-row">
        <span class="label">ID</span>
        <div class="value-container">
          <div class="value">
            <span>{{ loginId }}</span>
          </div>
          <div class="line"></div>
        </div>
      </div>
      <div class="profile-row">
        <span class="label">Password</span>
        <div class="value-container">
          <div class="value">
            <span>********</span>
            <button class="button-change">Change</button>
          </div>
          <div class="line"></div>
        </div>
      </div>
      <div class="profile-row">
        <span class="label">Name</span>
        <div class="value-container">
          <div class="value">
            <span>{{ nickname }}</span>
          </div>
          <div class="line"></div>
        </div>
      </div>
      <div class="profile-row">
        <span class="label">Email</span>
        <div class="value-container">
          <div class="value">
            <span>{{ email }}</span>
            <button class="button-change">Change</button>
          </div>
          <div class="line"></div>
        </div>
      </div>
    </div>
    <button @click="logout" class="button-logout">Log Out</button>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "UserProfile",
  setup() {
    const router = useRouter();

    const loginId = ref(localStorage.getItem("LoginId"));
    const email = ref(localStorage.getItem("Email"));
    const nickname = ref(localStorage.getItem("Nickname"));

    const logout = () => {
      localStorage.clear();
      alert("You have been logged out.");
      router.push("/");
    };

    return {
      loginId,
      email,
      nickname,
      logout,
    };
  },
};
</script>

<style scoped>
.userInfoTitle {
  margin-top: 0px;
  margin-bottom: 40px;
  text-align: center;
  color: #007bff;
}
.user-profile {
  max-width: 400px;
  margin: 50px auto;
  padding: 40px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #ffffff;
}

.profile-info {
  margin-bottom: 20px;
  font-size: small;
}

.profile-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.label {
  font-weight: bold;
  color: #555;
  flex: 1;
  margin-right: 10px;
}

.value-container {
  flex: 3;
  display: flex;
  flex-direction: column;
}

.value {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.value span {
  flex: 1;
  color: #333;
}

.line {
  height: 1px;
  background-color: #ddd;
  width: 100%;
  margin-top: 5px;
}

.button-change {
  margin-left: 10px;
  background-color: #ffffff;
  border: 2px solid #007bff;
  color: #007bff;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.button-logout {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  margin-top: 20px;
}
</style>
