<template>
  <div class="signup-page">
    <h2 v-if="role === 'manager'" class="admin-label">관리자</h2>
    <h2 class="signTitle">{{ $t("signup") }}</h2>
    <form @submit.prevent="submitSignUp">
      <label class="title">
        {{ $t("loginId") }}
        <input type="text" v-model="loginId" @blur="validateLoginId" required />
        <span v-if="loginIdError" class="error">{{ loginIdError }}</span>
      </label>
      <label class="title">
        {{ $t("loginPw") }}
        <input type="password" v-model="loginPw" @blur="validateLoginPw" required />
        <span v-if="loginPwError" class="error">{{ loginPwError }}</span>
      </label>
      <label class="title">
        {{ $t("loginPwConfirm") }}
        <input type="password" v-model="loginPwConfirm" @blur="validateLoginPwConfirm" required />
        <span v-if="loginPwConfirmError" class="error">{{ loginPwConfirmError }}</span>
      </label>
      <label class="title">
        {{ $t("nickname") }}
        <input type="text" v-model="nickname" @blur="validateNickname" required />
        <span v-if="nicknameError" class="error">{{ nicknameError }}</span>
      </label>
      <label class="title">
        {{ $t("email") }}
        <input type="email" v-model="email" @blur="validateEmail" required />
        <span v-if="emailError" class="error">{{ emailError }}</span>
      </label>
      <label class="title">
        {{ $t("country") }}
        <CustomCountry v-model="country" />
        <span v-if="countryError" class="error">{{ countryError }}</span>
      </label>
      <label v-if="role === 'manager'" class="title">
        {{ $t("grade") }}
        <input type="number" v-model="grade" @blur="validateGrade" required />
        <span v-if="gradeError" class="error">{{ gradeError }}</span>
      </label>
      <button type="submit" class="button-signup">{{ $t("signup") }}</button>
    </form>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { doSignUp } from "@/components/authentication/AuthService";
import CustomCountry from "@/components/authentication/CustomCountry.vue";

export default {
  name: "SignUpPage",
  props: ["role"],
  components: {
    CustomCountry,
  },
  setup(props) {
    const router = useRouter();

    const loginId = ref(props.role === "manager" ? "admin" : "boyzone8");
    const loginPw = ref("k1234567");
    const loginPwConfirm = ref("k1234567");
    const nickname = ref(props.role === "manager" ? "총괄책임자" : "오리옹");
    const email = ref("boyzone8@naver.com");
    const country = ref("");
    const grade = ref(props.role === "manager" ? "1" : null);

    const loginIdError = ref("");
    const loginPwError = ref("");
    const loginPwConfirmError = ref("");
    const nicknameError = ref("");
    const emailError = ref("");
    const countryError = ref("");
    const gradeError = ref("");

    onMounted(() => {
      // 로그인 상태와 사용자 유형, 등급을 localStorage에서 확인
      const isLoggedIn = localStorage.getItem("LoggedIn") === "true";
      const userType = localStorage.getItem("UserType");
      const grade = localStorage.getItem("Grade");

      console.log("props.role:", props.role);

      // 권한이 없으면 메시지를 표시하고 로그인 페이지로 리디렉션
      if (props.role === "manager") {
        if (!isLoggedIn || userType !== "manager" || grade !== "1") {
          alert("Signup 권한이 없습니다.");
          router.push("/login");
        } else {
          console.log("SignUP Manager");
        }
      } else {
        console.log("SignUP Customer");
      }
    });

    const validateLoginId = () => {
      const idPattern = /^[a-zA-Z0-9]{4,12}$/;
      loginIdError.value = !idPattern.test(loginId.value) ? "아이디가 유효하지 않습니다." : "";
    };

    const validateLoginPw = () => {
      const loginPwPattern = /^(?=.*\d)(?=.*[a-zA-Z]).{6,}$/;
      loginPwError.value = !loginPwPattern.test(loginPw.value) ? "비밀번호가 유효하지 않습니다." : "";
    };

    const validateLoginPwConfirm = () => {
      loginPwConfirmError.value = loginPw.value !== loginPwConfirm.value ? "비밀번호가 일치하지 않습니다." : "";
    };

    const validateNickname = () => {
      nicknameError.value = nickname.value.length < 2 ? "닉네임이 유효하지 않습니다." : "";
    };

    const validateEmail = () => {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      emailError.value = !emailPattern.test(email.value) ? "이메일이 유효하지 않습니다." : "";
    };

    const validateCountry = () => {
      countryError.value = !country.value ? "국가를 선택해주세요." : "";
    };

    const validateGrade = () => {
      gradeError.value = grade.value && (grade.value < 1 || grade.value > 5) ? "등급이 유효하지 않습니다." : "";
    };

    const submitSignUp = async () => {
      validateLoginId();
      validateLoginPw();
      validateLoginPwConfirm();
      validateNickname();
      validateEmail();
      validateCountry();
      if (props.role === "manager") validateGrade();

      if (loginIdError.value || loginPwError.value || loginPwConfirmError.value || nicknameError.value || emailError.value || countryError.value || gradeError.value) {
        return;
      }

      const result = await doSignUp({
        loginId: loginId.value,
        loginPw: loginPw.value,
        nickname: nickname.value,
        email: email.value,
        country: country.value,
        grade: props.role === "manager" ? grade.value : undefined,
        userType: props.role,
      });

      if (result.success) {
        alert(result.message);
        router.push({
          path: "/login",
          query: {
            loginId: loginId.value,
            loginPw: loginPw.value,
            userType: props.role,
          },
        });
      } else {
        alert(result.message);
      }
    };

    return {
      loginId,
      loginPw,
      loginPwConfirm,
      nickname,
      email,
      country,
      grade,
      loginIdError,
      loginPwError,
      loginPwConfirmError,
      nicknameError,
      emailError,
      countryError,
      gradeError,
      validateLoginId,
      validateLoginPw,
      validateLoginPwConfirm,
      validateNickname,
      validateEmail,
      validateCountry,
      validateGrade,
      submitSignUp,
    };
  },
};
</script>

<style scoped>
.signTitle {
  font-weight: bold;
  color: #007bff;
}

.title {
  font-size: small;
  font-weight: bold;
  color: #5a5a5a;
}

.button-signup {
  margin-top: 20px;
}
.admin-label {
  color: red;
  font-weight: bold;
  text-align: center;
}

.signup-page {
  max-width: 400px;
  margin: 50px auto;
  padding: 40px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
}

.signup-page h2 {
  text-align: center;
  margin-bottom: 20px;
}

.signup-page label {
  display: block;
  margin-bottom: 10px;
}

.signup-page input,
.signup-page select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  box-sizing: border-box;
  outline: none;
  border-width: 1px;
  border: 1px solid #ccc;
  color: #535353;
}

.signup-page button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.error {
  color: red;
  font-size: 0.9em;
}
.selected-option {
  margin-top: 5px;
}
</style>
