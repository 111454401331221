<template>
  <div v-if="isLoggedIn" class="issue-page">
    <h1>{{ $t("quoteIssue") }}</h1>

    <div class="form-group">
      <label for="productFix">{{ $t("productName") }} :</label>
      <input v-model="dataIssue.productFix" type="text" id="productFix" class="text-input" placeholder="$t('productNameHint')" required />
    </div>

    <div class="form-group">
      <label for="quantityFix">{{ $t("quantity") }} :</label>
      <input v-model.number="dataIssue.quantityFix" type="number" id="quantityFix" class="number-input" min="1" placeholder="Enter quantity" required />
    </div>

    <div class="form-group">
      <label for="brandFix">{{ $t("brand") }} :</label>
      <input v-model="dataIssue.brandFix" type="text" id="brandFix" class="text-input" placeholder="$t('brandHint')" required />
    </div>

    <div class="form-group">
      <label for="referenceLink">{{ $t("referenceLink") }} :</label>
      <a :href="referenceLink" target="_blank" rel="noopener noreferrer">{{ referenceLink }}</a>
    </div>

    <!-- customerImages -->
    <div class="form-group">
      <label>고객 등록 이미지:</label>
      <div v-if="customerImages.length > 0">
        <div v-for="(image, index) in customerImages" :key="index" class="image-input-group">
          <div class="file-box">
            <div class="image-preview" @click="showImageModal(image.url)">
              <img v-if="image.url" :src="image.url" alt="Uploaded Image" />
              <span v-else>No image</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <span>이미지 없음</span>
      </div>
    </div>

    <!-- ManagerImage와 + 버튼 -->
    <div class="form-group">
      <label>매니저 등록 이미지 :</label>

      <div v-for="(image, index) in managerImages" :key="index" class="image-input-group">
        <!-- 전체 영역을 div로 감싸기 -->
        <div class="file-box">
          <div class="image-preview" @click="showImageModal(image.url)">
            <img v-if="image.url" :src="image.url" alt="Uploaded Image" />
            <span v-else>No image</span>
            <!-- 모달 -->
            <div v-if="isModalVisible" class="modal-overlay" @click="closeModal">
              <div class="modal-content" @click.stop>
                <img :src="modalImage" alt="Full Image" class="modal-img-size" />
                <!-- X 버튼 추가 -->
                <button class="modal-close-button" @click="closeModal">X</button>
              </div>
            </div>
          </div>
          <div class="image-button-group">
            <!-- - 버튼: 이미지를 선택한 경우에만 표시 -->
            <button v-if="image.url" type="button" class="image-button-remove" @click="removeImageSlot(index)">-</button>
            <!-- Select File 버튼 -->
            <button type="button" class="image-button-change" @click="changeImageFile(index)">Select File</button>
            <input ref="'managerImage' + index" type="file" :id="'managerImage' + index" class="file-input" @change="loadImageFile($event, index)" accept="image/*" />
            <!-- + 버튼: 마지막 슬롯이며 최대 5개가 아닐 때만 표시 -->
            <button v-if="image.url && index === managerImages.length - 1 && managerImages.length < 5" type="button" class="image-button-add" @click="addImageSlot">+</button>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="extraRequest">{{ $t("additionalRequests") }} :</label>
      <span id="extraRequest">{{ extraRequest }}</span>
    </div>

    <div class="form-group">
      <label for="unitPrice">Unit Price:</label>
      <input v-model="dataIssue.unitPrice" type="number" id="unitPrice" class="number-input" min="0" placeholder="Enter unit price" required />
    </div>

    <div class="form-group">
      <label for="notice">Notice:</label>
      <textarea v-model="dataIssue.notice" id="notice" class="text-area-input" placeholder="Enter additional notes" maxlength="1000" @input="updateCharCount"></textarea>
    </div>

    <div>
      <button @click.prevent="setProductIssue" :disabled="!isFormValid">Issue</button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import apiService from "@/api/apiService";

export default {
  name: "M_1Issue",
  setup() {
    const router = useRouter();
    const route = useRoute();

    const isLoggedIn = ref(localStorage.getItem("LoggedIn") === "true");
    const isModalVisible = ref(false);
    const modalImage = ref("");
    const charCount = ref(0);

    const referenceLink = ref(route.query.referenceLink || "");
    const customerImages = ref([]);
    const managerImages = ref([]);
    const extraRequest = ref(route.query.extraRequest || "");

    // for product_issues table
    const dataIssue = ref({
      idRequest: route.params.id_request || null,
      productFix: route.query.product || "",
      quantityFix: Number(route.query.quantity) || 1,
      brandFix: route.query.brand || "",
      unitPrice: 0,
      hasImage: Number(route.query.hasImage) || 1,
      notice: "",
    });

    if (!isLoggedIn.value) {
      router.push("/login/manager");
    }

    const getProductImages = async () => {
      try {
        const response = await apiService.getProductImages(dataIssue.value.idRequest);
        console.log("getProductImages:", response); // 데이터 확인

        if (Array.isArray(response) && response.length > 0) {
          // 이미지 배열에서 슬롯 번호 추출 및 정렬
          const imagesWithSlot = response.map((image) => {
            const imageName = image.split("/").pop(); // 파일명 추출
            const slotMatch = imageName.match(/customer(\d+)_/); // 슬롯 번호 추출
            const slotNumber = slotMatch ? parseInt(slotMatch[1], 10) : 0;
            return { image, slotNumber };
          });

          // 슬롯 번호를 기준으로 정렬
          imagesWithSlot.sort((a, b) => a.slotNumber - b.slotNumber);

          // 정렬된 배열을 사용하여 `customerImages` 설정
          customerImages.value = imagesWithSlot.map((item) => {
            const imageObject = {
              url: item.image, // 이미지 URL
              fileName: item.image.split("_").pop(), // 파일명 추출 (예: testA.jpg)
              file: null, // 초기 파일은 없음
              fileIndex: item.slotNumber, // 슬롯 번호 사용
            };
            console.log("Adding image object:", imageObject); // 처리된 데이터 확인
            return imageObject;
          });
        } else {
          customerImages.value = [];
          console.warn("No images found or invalid response format");
        }
      } catch (error) {
        console.error("Failed to fetch customer images:", error.message);
        customerImages.value = [];
      }
    };

    const loadImageFile = (event, index) => {
      console.log(`File upload triggered for index: ${index}`); // 디버깅
      console.log("Current managerImages before update:", managerImages.value); // 배열 상태 확인
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          managerImages.value[index] = {
            file,
            fileName: file.name,
            url: reader.result, // 파일의 Data URL을 미리보기로 사용
            fileIndex: index + 1,
          };
        };
        reader.readAsDataURL(file);
      }
    };

    const showImageModal = (url) => {
      if (url) {
        modalImage.value = url;
        isModalVisible.value = true;
      }
    };

    const closeModal = () => {
      isModalVisible.value = false;
      modalImage.value = "";
    };

    const changeImageFile = (index) => {
      const fileInput = document.getElementById(`managerImage${index}`);
      if (fileInput) {
        fileInput.click(); // 파일 선택 창 열기
      }
    };

    const removeImageSlot = (index) => {
      if (index < 0 || index >= managerImages.value.length) {
        console.warn("Invalid slot index.");
        return;
      }

      console.log(`Removing slot ${index + 1}. Current managerImages:`, managerImages.value);

      if (managerImages.value.length === 1) {
        // 마지막 슬롯만 남아있을 때는 데이터를 초기화
        managerImages.value[0] = {
          file: null,
          fileName: null,
          url: null,
          fileIndex: 1, // 기본 슬롯 번호
        };
      } else {
        // 특정 슬롯 제거
        managerImages.value = managerImages.value.filter((_, i) => i !== index);

        // 마지막 슬롯에 대한 추가 처리: "No image" 상태 보장
        const lastIndex = managerImages.value.length - 1;
        if (lastIndex >= 0 && managerImages.value[lastIndex].url === null && managerImages.value[lastIndex].file === null) {
          managerImages.value[lastIndex] = {
            ...managerImages.value[lastIndex],
            file: null,
            fileName: null,
            url: null,
          };
        }
      }

      console.log(`Updated managerImages after removal:`, managerImages.value);
    };

    const addImageSlot = () => {
      console.log("Adding new slot. Current managerImages:", managerImages.value); // 디버깅
      if (managerImages.value.length < 5) {
        managerImages.value.push({
          file: null,
          fileName: null,
          url: null,
          fileIndex: managerImages.value.length + 1,
        });
        console.log("Slot added. Updated managerImages:", managerImages.value); // 배열 상태 확인
      } else {
        alert("최대 5개의 이미지만 업로드할 수 있습니다.");
      }
    };

    const updateCharCount = () => {
      charCount.value = dataIssue.value.notice.length;
    };

    const isSubmitting = ref(false); // 요청 중 상태 관리

    const setProductIssue = async () => {
      if (isSubmitting.value) return; // 이미 요청 중이면 실행하지 않음
      isSubmitting.value = true;
      console.log("Current managerImages:", managerImages.value);

      try {
        const formData = new FormData();
        formData.append("userId", localStorage.getItem("UserId"));
        formData.append("idRequest", dataIssue.value.idRequest);
        formData.append("productFix", dataIssue.value.productFix);
        formData.append("quantityFix", dataIssue.value.quantityFix);
        formData.append("brandFix", dataIssue.value.brandFix);
        formData.append("unitPrice", dataIssue.value.unitPrice);
        formData.append("notice", dataIssue.value.notice);

        // 최종적으로 슬롯 번호 조정
        managerImages.value.forEach((image, index) => {
          const fileIndex = index + 1; // 재정렬된 파일 인덱스
          image.fileIndex = fileIndex; // 슬롯 번호 업데이트
          if (image.file) {
            // 새로 추가된 이미지
            console.log(`New File Name [Slot ${fileIndex}]: ${image.file.name}`);
            formData.append(`managerImage${fileIndex}`, image.file);
          } else if (image.url) {
            // 기존 서버 이미지
            console.log(`Server URL Name [Slot ${fileIndex}]: ${image.url}`);
            formData.append(`imageURL${fileIndex}`, image.url);
          } else {
            console.warn(`No valid data for Slot ${fileIndex}`);
          }
        });

        const response = await apiService.setProductIssue(formData);
        console.log("Issue response:", response);
        alert("견적이 성공적으로 발행되었습니다!");
        router.push("/manager");
      } catch (error) {
        console.error("Failed to submit issue:", error.message);
        alert("Failed to submit issue.");
      }
    };

    const isFormValid = computed(() => {
      const { productFix, quantityFix, brandFix, unitPrice } = dataIssue.value;
      return productFix.trim() !== "" && quantityFix > 0 && brandFix.trim() !== "" && unitPrice >= 0;
    });

    onMounted(() => {
      if (Number(dataIssue.value.hasImage) === 1) {
        getProductImages().then(() => {
          // 이미지가 하나도 없는 경우 빈 슬롯 추가
          if (managerImages.value.length === 0) {
            managerImages.value.push({
              file: null,
              fileName: null,
              url: null,
              fileIndex: 1, // 기본 슬롯 번호
            });
          }
        });
      } else {
        // 이미지가 없는 경우 기본 슬롯 추가
        managerImages.value.push({
          file: null,
          fileName: null,
          url: null,
          fileIndex: 1, // 기본 슬롯 번호
        });
      }
    });

    return {
      isLoggedIn,
      dataIssue,
      referenceLink,
      extraRequest,
      customerImages,
      managerImages,
      charCount,
      isFormValid,
      isModalVisible,
      modalImage,
      updateCharCount,
      loadImageFile,
      changeImageFile,
      showImageModal,
      closeModal,
      removeImageSlot,
      addImageSlot,
      setProductIssue,
    };
  },
};
</script>

<style scoped>
.issue-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0;
  margin-top: 0;
  min-height: 0;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  width: 100%;
  max-width: 80%;
}

label {
  margin-bottom: 1px;
  font-weight: bold;
}

.image-input-group {
  margin-bottom: 8px;
}

.image-button-group {
  display: flex;
  flex-direction: column;
  align-items: center; /* 버튼들을 가운데 정렬 */
  gap: 10px; /* 버튼들 간 간격 */
}

.image-button-change,
.image-button-remove,
.image-button-add {
  margin-left: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 4px 8px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
}

.image-button-change:hover,
.image-button-remove:hover,
.image-button-add:hover {
  background-color: #0056b3;
}

/* 클릭 가능한 전체 박스 */
.file-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%; /* 부모 컨테이너의 너비를 채움 */
  max-width: 100%; /* 다른 input과 일치 */
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  gap: 16px;
  box-sizing: border-box; /* padding 포함한 크기 계산 */
}

.file-box:hover {
  border-color: #aaa; /* hover 시 테두리 색상 */
}

/* 숨겨진 파일 입력 */
.file-input {
  display: none;
}

/* 이미지 미리보기 */
.image-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border: none;
  border-radius: 4px;
  overflow: hidden;
}

.image-preview img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.modal-img-size {
  max-width: 70vw !important;
  max-height: 70vh !important;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
}

.modal-content {
  max-width: 90%;
  max-height: 90%;
  position: relative;
}

.modal-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
  cursor: default;
}

.modal-close-button {
  z-index: 1002; /* X 버튼은 모달 내용보다 위 */
  background-color: #007bff; /* 테스트용 배경색 */
  color: white; /* 글자 색상 */
  position: absolute; /* 위치 고정 */
  top: 10px; /* 모달 상단에서 10px 아래 */
  right: 10px; /* 모달 오른쪽에서 10px 왼쪽 */
  border: none;
  font-size: 20px; /* 버튼 크기 */
  font-weight: bold;
  cursor: pointer; /* 클릭 가능 */
  z-index: 1002; /* 다른 요소보다 앞에 표시 */
  width: 40px; /* 테스트용 버튼 너비 */
  height: 40px; /* 테스트용 버튼 높이 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-close-button:hover {
  color: #ccc; /* 호버 시 색상 변경 */
}

/* Change File 버튼 */
.file-change-button {
  padding: 8px 16px;
  font-size: 14px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.file-change-button:hover {
  background-color: #0056b3;
}

.custom-file-label {
  display: inline-block;
  padding: 4px 8px;
  font-size: 14px;
  color: #fff;
  background-color: #007bff;
  border-radius: 4px;
  cursor: pointer;
}

.custom-file-label:hover {
  background-color: #0056b3;
}

.number-input,
.text-input,
.text-area-input,
.test-box {
  display: block;
  width: 100%; /* 부모 크기를 채우도록 */
  max-width: 100%; /* 부모 제한에 맞춤 */
  padding: 8px; /* 동일한 패딩 */
  font-size: 16px;
  border: 1px solid #ccc; /* 동일한 테두리 */
  border-radius: 4px;
  box-sizing: border-box; /* 패딩과 경계를 포함한 크기 계산 */
  background-color: #fff; /* 동일한 배경색 */
}

.text-area-input {
  height: 200px;
  resize: vertical;
}

.char-counter {
  font-size: 12px;
  color: #666;
  text-align: right;
}

button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  padding: 10px 20px;
}

button:hover {
  opacity: 0.9;
}

button:hover:enabled {
  background-color: #0056b3;
}

button:disabled {
  padding: 10px 20px;
  background-color: #ccc;
  cursor: not-allowed;
}

.loading {
  color: gray;
  font-style: italic;
  text-align: center;
  margin-top: 10px;
  animation: blink 1.5s linear infinite;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
</style>
