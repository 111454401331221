<template>
  <div class="main-page">
    <div v-if="showManager" class="admin-section">
      <span class="admin-link" @click="goToManager">{{ $t("manager") }}</span>
    </div>

    <div class="user-info">
      <span v-if="isLoggedIn" class="link" @click="goToUserProfile">{{ nickname }}</span>
      <span v-else class="link" @click="goToLogin">{{ $t("login") }}</span>
    </div>

    <!-- 제목 (5번 클릭 감지) -->
    <h1 @click="handleWelcomeClick">{{ $t("welcome") }}</h1>

    <div class="button-group">
      <button @click="goToVisit" class="button-main">{{ $t("visitButton") }}</button>
      <button @click="goToProduct" class="button-main">{{ $t("productButton") }}</button>
    </div>

    <!-- Footer -->
    <footer class="footer">
      <div class="footer-links">
        <a href="#">{{ $t("language") }}</a>
        <a href="#">{{ $t("termOfService") }}</a>
        <a href="#">{{ $t("privacyPolicy") }}</a>
        <a href="#">{{ $t("faqSupport") }}</a>
      </div>
      <div class="footer-info">사업자 등록번호 : 814-87-03071<br />Copyright © [2004] (주) 무한시스템즈. All Rights Reserved</div>
    </footer>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { doLogout } from "@/components/authentication/AuthService";
// import apiService from "@/api/apiService";

export default {
  name: "PageMain",
  setup() {
    const router = useRouter();
    const { locale } = useI18n();

    const isLoggedIn = ref(localStorage.getItem("LoggedIn") === "true");
    const nickname = ref(localStorage.getItem("Nickname"));
    const userType = ref(localStorage.getItem("UserType"));

    // 관리자 섹션 표시 여부와 클릭 횟수
    const showManager = ref(false);
    const welcomeClickCount = ref(0);

    // welcome 클릭 이벤트
    const handleWelcomeClick = () => {
      welcomeClickCount.value += 1;
      if (welcomeClickCount.value >= 5) {
        showManager.value = true; // 5번 클릭하면 관리자 섹션 표시
      }
    };

    const goToLogin = () => {
      router.push("/login");
    };

    const goToUserProfile = () => {
      router.push("/user-profile");
    };

    const goToManager = () => {
      const isLoggedIn = localStorage.getItem("LoggedIn") === "true";
      const userType = localStorage.getItem("UserType");

      if (isLoggedIn && userType === "manager") {
        router.push("/manager"); // 매니저로 로그인 상태
      } else {
        router.push("/login/manager"); // 로그인 필요
      }
    };

    const submitLogout = async () => {
      await doLogout();
      welcomeClickCount.value = 0;
      showManager.value = false;

      isLoggedIn.value = false;
      nickname.value = null;
      router.push("/"); // 홈 화면으로 이동
    };

    const goToVisit = () => {
      router.push("/visit");
    };

    const goToProduct = () => {
      if (isLoggedIn.value) {
        router.push("/product");
      } else {
        alert("로그인부터 진행해주세요.");
        router.push("/login"); // 로그인 페이지로 이동
      }
    };

    const toggleLanguage = () => {
      locale.value = locale.value === "ko" ? "en" : "ko";
    };

    return { isLoggedIn, nickname, userType, showManager, handleWelcomeClick, goToLogin, goToUserProfile, goToManager, submitLogout, goToVisit, goToProduct, toggleLanguage };
  },
};
</script>

<style scoped>
.main-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
}

.admin-section {
  position: fixed;
  top: 10px;
  left: 10px;
}

.admin-link {
  font-size: 22px;
  font-weight: bold;
  color: #ff4500; /* 관리자 색상 */
  cursor: pointer;
}

.button-main {
  margin: 10px;
  padding: 10px 20px;
  font-size: 20px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  height: 80px;
  width: 150px; /* 동일한 너비 지정 */
  text-align: center; /* 텍스트 가운데 정렬 */
}

.user-info {
  position: fixed;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
}

.user-info .link {
  cursor: pointer;
  color: #007bff;
  font-weight: bold;
  font-size: 22px;
}

.user-info .separator {
  margin: 0 15px;
  color: #007bff;
  font-size: 22px;
}

.language-switch .language-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-weight: bold;
  color: #007bff;
  font-size: 22px;
  outline: none;
}

.button-group {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: #f8f9fa;
  padding: 10px 0;
  font-size: 14px;
  color: #666;
}

.footer-links {
  margin-bottom: 5px;
}

.footer-links a {
  margin: 0 10px;
  color: #007bff;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer-info {
  font-size: 12px;
  color: #666;
}
</style>
