<template>
  <div class="food-list">
    <ul>
      <li v-for="(dish, index) in foods" :key="index">{{ dish }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "VisitFood",
  props: {
    foods: Array,
  },
};
</script>

<style scoped>
/* 필요한 스타일 추가 */
</style>
