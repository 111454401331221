import apiService from "@/api/apiService";

export async function doSignUp({ loginId, loginPw, nickname, email, country, grade, userType }) {
  try {
    const response = await apiService.doSignUp({
      loginId,
      loginPw,
      nickname,
      email,
      country,
      grade: userType === "manager" ? grade : undefined,
      userType,
    });

    if (response && response.message) {
      return { success: true, message: response.message };
    } else {
      return { success: false, message: "서버에서 유효한 응답을 받지 못했습니다." };
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || "서버에 연결할 수 없습니다. 나중에 다시 시도해주세요.";
    console.error("회원가입 요청 오류:", error);
    return { success: false, message: errorMessage };
  }
}

export async function doLogin({ loginId, loginPw, userType }) {
  try {
    const response = await apiService.doLogin({ loginId, loginPw, userType });

    if (response.result) {
      const user = response.user;

      console.log("로그인 상태 :", user);

      // localStorage에 로그인 상태, 닉네임, 사용자 유형, 등급(grade) 저장
      localStorage.setItem("LoggedIn", "true");
      localStorage.setItem("UserType", userType);
      localStorage.setItem("UserId", userType === "manager" ? user.id_manager : user.id_customer);
      localStorage.setItem("CreatedAt", user.created_at);
      localStorage.setItem("LoginId", user.login_id);
      localStorage.setItem("LoginPw", user.login_pw);
      localStorage.setItem("Nickname", user.nickname);
      localStorage.setItem("Email", user.email);
      localStorage.setItem("Country", user.country);
      localStorage.setItem("Grade", userType === "manager" ? user.grade : "0"); // 매니저일 때는 grade 저장, 사용자일 때는 0
      localStorage.setItem("Available", userType === "customer" ? user.available : "0"); // 매니저일 때는 0

      console.log("로그인 상태 저장 완료:", {
        UserType: userType,
        UserId: localStorage.getItem("UserId"),
        Nickname: localStorage.getItem("Nickname"),
        Country: localStorage.getItem("Country"),
        Grade: localStorage.getItem("Grade"),
        Available: localStorage.getItem("Available"),
      });

      return { success: true, role: userType }; // 성공하면 role도 반환
    } else {
      alert("로그인에 실패했습니다.");
      return { success: false };
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || "서버에 연결할 수 없습니다. 나중에 다시 시도해주세요.";
    console.error("로그인 요청 오류:", error);
    alert(errorMessage);
    return { success: false };
  }
}

export const doLogout = async () => {
  try {
    const userId = localStorage.getItem("UserId");
    const userType = localStorage.getItem("UserType");

    console.log("UserId:", userId);

    // 서버에 로그아웃 요청
    await apiService.doLogout({ userId, userType });
    console.log("Logout API 호출 성공");

    localStorage.clear();
  } catch (error) {
    console.error("로그아웃 중 오류 발생:", error.message);
    alert("로그아웃 실패. 다시 시도해 주세요.");
  }
};
