// src/router/index.js
import { createRouter, createWebHistory } from "vue-router";

import Main from "@/components/Main.vue";
import Login from "@/components/authentication/Login.vue";
import Signup from "@/components/authentication/Signup.vue";
import UserProfile from "@/components/authentication/UserProfile.vue";
import VisitMain from "@/components/service/visit/V_0Main.vue";
import ProductMain from "@/components/service/product/P_0Main.vue";
import Product_1Request from "@/components/service/product/P_1Request.vue";
import Product_2Payment from "@/components/service/product/P_2Payment.vue";
import Product_3Edit from "@/components/service/product/P_3EditRequest.vue";
import Manager from "@/components/manager/M_0Main.vue";
import Issue from "@/components/manager/M_1Issue.vue";

const routes = [
  // User
  { path: "/", component: Main },
  { path: "/login/:role?", component: Login, props: (route) => ({ role: route.params.role || "customer" }) },
  { path: "/signup/:role?", component: Signup, props: (route) => ({ role: route.params.role || "customer" }) },
  { path: "/user-profile", component: UserProfile },
  { path: "/visit", component: VisitMain },
  { path: "/product", component: ProductMain },
  { path: "/product/request", name: "P_1Request", component: Product_1Request },
  { path: "/product/payment/:id_request", name: "P_2Payment", component: Product_2Payment },
  { path: "/product/edit-request/:id_request", name: "P_3EditRequest", component: Product_3Edit },
  { path: "/manager", component: Manager },
  { path: "/manager/issue/:id_request", component: Issue },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
