<template>
  <div class="login-page">
    <h2 v-if="role === 'manager'" class="admin-label">관리자</h2>
    <h2 class="loginTitle">{{ $t("login") }}</h2>

    <form @submit.prevent="submitLogin">
      <div class="inputForm">
        <label> {{ $t("loginId") }} <input type="text" class="userInfo" v-model="loginId" required /> </label>
        <label> {{ $t("loginPw") }} <input type="password" class="userInfo" v-model="loginPw" required /> </label>
      </div>
      <button class="button-signup" type="submit">{{ $t("login") }}</button>
      <button class="button-signup" @click="goToSignUp">{{ $t("signup") }}</button>
    </form>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { doLogin, doLogout } from "@/components/authentication/AuthService";

export default {
  name: "LoginPage",
  props: ["role"],
  setup(props) {
    const router = useRouter();
    const route = useRoute();

    const loginId = ref(route.query.loginId || (props.role === "manager" ? "admin" : "aaaaaa"));
    const loginPw = ref(route.query.loginPw || "k1234567");
    const isLoggedIn = ref(false);

    onMounted(async () => {
      const loggedInRole = localStorage.getItem("UserType");
      if (localStorage.getItem("LoggedIn") === "true") {
        // 현재 role과 로그인된 role이 다르면 로그아웃 처리
        if (loggedInRole && loggedInRole !== props.role) {
          alert("접근 권한이 없습니다. 로그아웃합니다.");
          await doLogout();
          router.push("/login/manager");
        } else {
          // role이 일치하면 메인 페이지로 이동
          router.push("/");
        }
      } else {
        if (props.role === "manager") {
          console.log("Login Manager");
        } else {
          console.log("Login Customter");
        }
      }
    });

    const submitLogin = async () => {
      const result = await doLogin({
        loginId: loginId.value,
        loginPw: loginPw.value,
        userType: props.role,
      });

      if (result.success) {
        isLoggedIn.value = true;
        if (props.role === "manager") {
          router.push("/manager");
        } else {
          router.push("/"); // 일반 사용자는 /로 이동
        }
      }
    };

    const goToSignUp = () => {
      router.push("/signup");
    };

    return {
      loginId,
      loginPw,
      isLoggedIn,
      submitLogin,
      goToSignUp,
    };
  },
};
</script>

<style scoped>
.inputForm {
  margin-bottom: 20px;
}

.title {
  font-size: small;
  font-weight: bold;
  color: #5a5a5a;
}

.userInfo {
  color: #535353;
  outline: none;
  border-width: 1px;
  border: 1px solid #ccc;
}

.loginTitle {
  font-weight: bold;
  color: #007bff;
}

.admin-label {
  color: red;
  font-weight: bold;
  text-align: center;
}

.login-page {
  max-width: 400px;
  margin: 50px auto;
  padding: 40px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #ffffff;
}

.login-page h2 {
  text-align: center;
  margin-bottom: 20px;
}

.login-page label {
  display: block;
  margin-bottom: 10px;
}

.login-page input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  box-sizing: border-box;
}

.login-page button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button-signup {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #6c757d;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
</style>
