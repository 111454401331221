<template>
  <div id="app">
    <router-view />
    <!-- 현재 경로에 따라 각 Page가 표시됨 -->
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
#app {
  font-family: Arial, sans-serif;
  /* display: grid; */
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
